
// import { refreshUserContact } from '@/api/hubSpot';
import { sendLendioData } from '@/api/lendio';
import BaseButton from '@/components/baseComponents/BaseButton.vue';
import BaseCheckBox from '@/components/baseComponents/BaseCheckBox.vue';
import BaseRadioButtonGroup from '@/components/baseComponents/BaseRadioButtonGroup.vue';
import TitleDescriptionUniversal from '@/components/portal/TitleDescriptionUniversal.vue';
import {
  optionsCreditScore, optionsMoney, optionsMonthlySales, optionsTimeInBusiness,
} from '@/options/lendioOptions';
import { getLastPageNew, getNextPage } from '@/utils/redirectUtils';
import { Component, Vue } from 'vue-property-decorator';
import { mapGetters } from 'vuex';

@Component({
  components: {
    TitleDescriptionUniversal,
    BaseRadioButtonGroup,
    BaseCheckBox,
    BaseButton,
  },
  computed: {
    ...mapGetters('steps', ['isPreviousPageExist']),
  },
})
export default class LendioPage extends Vue {
  public isPreviousPageExist!: boolean;

  timerIdServer = 0;
  isButtonPreloaderShow = false;
  optionsMoney = optionsMoney;
  optionsCreditScore = optionsCreditScore;
  optionsTimeInBusiness = optionsTimeInBusiness;
  optionsMonthlySales = optionsMonthlySales;

  errors = {
    personalCreditScore: false,
    amountSeeking: false,
    timeInBusiness: false,
    averageMonthlySales: false,
    agreeWithTerms: false,
  };

  get personalCreditScore(): string {
    return this.$store.state.lendio.personalCreditScore;
  }
  set personalCreditScore(value: string) {
    this.$store.commit('lendio/setPersonalCreditScore', value);
  }
  get amountSeeking(): string {
    return this.$store.state.lendio.amountSeeking;
  }
  set amountSeeking(value: string) {
    this.$store.commit('lendio/setAmountSeeking', value);
  }
  get timeInBusiness(): string {
    return this.$store.state.lendio.timeInBusiness;
  }
  set timeInBusiness(value: string) {
    this.$store.commit('lendio/setTimeInBusiness', value);
  }
  get averageMonthlySales(): string {
    return this.$store.state.lendio.averageMonthlySales;
  }
  set averageMonthlySales(value: string) {
    this.$store.commit('lendio/setAverageMonthlySales', value);
  }
  get agreeWithTerms(): boolean {
    return this.$store.state.lendio.agreeWithTerms;
  }
  set agreeWithTerms(value: boolean) {
    this.$store.commit('lendio/setAgreeWithTerms', value);
  }
  get lendioFirstPageNotValid(): boolean {
    return (
      this.personalCreditScore.length === 0
      || this.amountSeeking.length === 0
      || this.timeInBusiness.length === 0
      || this.averageMonthlySales.length === 0
      || !this.agreeWithTerms
    );
  }

  async getLendioUrl(): Promise<void> {
    const formDataLendio = {
      email: this.$store.state.user.email,
      businessName: this.$store.state.user.companyName,
      amountSeeking: this.amountSeeking,
      timeInBusiness: this.timeInBusiness,
      averageMonthlySales: this.averageMonthlySales,
      personalCreditScore: this.personalCreditScore,
    };

    try {
      this.timeOutServerError();
      const urlLendio = ((await sendLendioData(formDataLendio)) as any).data;
      this.$store.commit('lendio/setLendioUrl', urlLendio);
      clearTimeout(this.timerIdServer);
      // await refreshUserContact();
    } catch (error: any) {
      this.popupError(error.response.data.message);
    }
  }
  popupError(errorText: string): void {
    this.$notify({
      group: 'main-notification',
      type: 'error',
      text: errorText,
    });
  }
  timeOutServerError(): void {
    this.timerIdServer = setTimeout(() => {
      this.$notify({
        group: 'main-notification',
        type: 'error',
        text: 'the server is not responding',
      });
    }, 15000);
  }
  errorLendioTerms(): void {
    this.$notify({
      group: 'main-notification',
      type: 'error',
      text: 'Please review and accept all terms and conditions to proceed with the application.',
    });
  }

  lastButtonAction(): void {
    this.$router.push({ name: getLastPageNew() });
  }
  async nextButtonAction(): Promise<void> {
    try {
      this.isButtonPreloaderShow = true;
      await this.getLendioUrl();
      this.$router.push({ name: getNextPage() });
    } catch (error: any) {
      this.popupError(error.response.data.message);
    } finally {
      this.isButtonPreloaderShow = false;
    }
  }
}
